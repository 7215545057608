import { NextSeo } from 'next-seo';
import React from 'react';

export type ISeoProps = {
    title: string | undefined;
    description: string | undefined;
};

const Seo = (props: ISeoProps) => {
    const { title, description } = props;
    return (
        <NextSeo
            title={title}
            description={description}
        />
    );
};

export default Seo;
