import Button from '@/components/general/Button';
import TextInput from '@/components/general/TextInput';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { googleLoginQueryFn, signUpQueryFn } from '@/queries/auth';
import useAuth from '@/contexts/AuthContext';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import { getDevice } from '@/api/config';
import EnvelopeIcon from 'public/assets/icons/envelope.svg';
import LockIcon from 'public/assets/icons/lock.svg';
import PersonIcon from 'public/assets/icons/person.svg';
import Link from 'next/link';
import { PasswordValidator } from '@/helpers/Validators';
import { useRouter } from 'next/navigation';
import i18next from 'i18n';
import { getLandingPageTrackingFromLocalStorage } from '@/helpers/LandingPageTracker';
import { getSkipOnboardingFromLocalStorage, getTempSkipOnboarding } from '@/helpers/SkipOnboarding';
import { AnimatePresence, motion } from 'framer-motion';
import { useGoogleLogin } from '@react-oauth/google';
import SocialLoginButton from '../SocialLoginButton';

export type ISignUpFormProps = {
    changeToLogin: () => void;
    onSignUpSuccess?: () => void;
};

const SignUpSchema = Yup.object().shape({
    email: Yup.string().email(i18next.t('error-text.invalid-email')).required(i18next.t('error-text.required')),
    username: Yup.string().min(4, i18next.t('error-text.username-min-length')).required(i18next.t('error-text.required')),
    password: PasswordValidator,
    passwordConfirmation: Yup.string().required(i18next.t('error-text.required'))
        .oneOf([Yup.ref('password')], i18next.t('error-text.password.passwords-not-match')),
    terms: Yup.boolean().required(i18next.t('error-text.required')).oneOf([true], i18next.t('error-text.accept-terms'))
});

const SignUpForm = (props: ISignUpFormProps) => {
    const { changeToLogin, onSignUpSuccess } = props;
    const { setUser, updateRefreshToken } = useAuth();
    const router = useRouter();

    const signUpMutation = useMutation({
        mutationFn: signUpQueryFn,
        onSuccess: (response) => {
            if (response && response.user) {
                const { user: resUser, refresh_token: refreshToken } = response;
                if (refreshToken && refreshToken !== '') {
                    updateRefreshToken(refreshToken);
                }
                if (getTempSkipOnboarding() === false && getSkipOnboardingFromLocalStorage() === false && process.env.NEXT_PUBLIC_ONBOARDING && process.env.NEXT_PUBLIC_ONBOARDING === '1') {
                    router.push('/onboarding');
                } else if (onSignUpSuccess) {
                    onSignUpSuccess();
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any).dataLayer) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).dataLayer.push({
                        event: 'signup'
                    });
                }

                setUser(resUser);
                toast.success(i18next.t('success-notification.signed-up'));
            }
        },
        onError: async (error) => {
            const exception = await ErrorHandler.transformError(error as Request);
            if (ErrorHandler.Auth.emailExist(exception)) {
                toast.error(i18next.t('error-notification.email-taken'));
            } else if (ErrorHandler.Auth.usernameExist(exception)) {
                toast.error(i18next.t('error-notification.username-taken'));
            } else {
                toast.error(i18next.t('error-notification.unexpected-error'));
            }
        },
    });

    const googleLoginMutation = useMutation({
        mutationFn: googleLoginQueryFn,
        onSuccess: (response) => {
            if (response && response.user) {
                const { user: resUser, refresh_token: refreshToken } = response;
                if (refreshToken && refreshToken !== '') {
                    updateRefreshToken(refreshToken);
                }
                if (getTempSkipOnboarding() === false && getSkipOnboardingFromLocalStorage() === false && process.env.NEXT_PUBLIC_ONBOARDING && process.env.NEXT_PUBLIC_ONBOARDING === '1') {
                    router.push('/onboarding');
                } else if (onSignUpSuccess) {
                    onSignUpSuccess();
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((window as any).dataLayer) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).dataLayer.push({
                        event: response.created ? 'signup' : 'login'
                    });
                }

                setUser(resUser);
                toast.success(i18next.t('success-notification.signed-up'));
            }
        },
        onError: async (error) => {
            const transformedError = await ErrorHandler.transformError(error as Request);
            if (ErrorHandler.Auth.unauthorized(transformedError)) {
                toast.error(i18next.t('error-notification.failed-google-login'));
            } else {
                toast.error(i18next.t('error-notification.unexpected-error'));
            }
        },
    });

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (tokenResponse) => {
            googleLoginMutation.mutate({
                code: tokenResponse.code,
                device: await getDevice(),
                signed_up_from: getLandingPageTrackingFromLocalStorage()
            });
        },
    });

    const oAuthOptions = useMemo(() => {
        return (
            <motion.div key="loginoptions" className="" exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
                {/* <SocialLoginButton
                    network="apple"
                    disabled
                    onClick={() => {

                    }}
                >
                    APPLE
                </SocialLoginButton> */}
                <SocialLoginButton
                    network="google"
                    onClick={googleLogin}
                >
                    Google
                </SocialLoginButton>
                <div className="my-4 font-secondary text-sm font-[100] bg-gradient-blue bg-clip-text text-transparent">{i18next.t('general.or-signup-with-email')}</div>
            </motion.div>
        );
    }, [googleLogin]);

    return (
        <div>
            <div className="text-center space-y-[24px] flex flex-col">
                <div>
                    <span className="text-[16px] bg-gradient-blue bg-clip-text text-transparent font-[500] mb-[4px]">{i18next.t('general.signup')}</span>
                    <h3 className="font-heading text-[48px] leading-[48px]">{i18next.t('general.welcome')}</h3>
                </div>
                <div className="font-[500]">
                    <AnimatePresence mode="wait">
                        {oAuthOptions}
                        <Formik
                            initialValues={{
                                email: '',
                                username: '',
                                password: '',
                                passwordConfirmation: '',
                                terms: false,
                            }}
                            validationSchema={SignUpSchema}
                            onSubmit={async (values) => {
                                try {
                                    signUpMutation.mutate({
                                        body: {
                                            device: await getDevice(),
                                            email_address: values.email,
                                            password: values.password,
                                            username: values.username,
                                            signed_up_from: getLandingPageTrackingFromLocalStorage()
                                        }
                                    });
                                } catch (error) {
                                    toast.error(i18next.t('error-notification.recapcha'));
                                }
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleSubmit,
                                setFieldValue
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-3 relative">
                                        <div>
                                            <TextInput
                                                autoFocus
                                                errored={errors.email && touched.email}
                                                type="text"
                                                inputPlaceholder={i18next.t('input-text.email')}
                                                name="email"
                                                autoComplete="username"
                                                onChange={handleChange}
                                                value={values.email}
                                                icon={<EnvelopeIcon />}
                                            />
                                            {errors.email && touched.email ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <TextInput
                                                autoComplete="off"
                                                errored={errors.username && touched.username}
                                                type="text"
                                                inputPlaceholder={i18next.t('input-text.username')}
                                                name="username"
                                                onChange={handleChange}
                                                value={values.username}
                                                icon={<PersonIcon />}
                                            />
                                            {errors.username && touched.username ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.username}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <TextInput
                                                errored={errors.password && touched.password}
                                                type="password"
                                                inputPlaceholder={i18next.t('input-text.password')}
                                                name="password"
                                                autoComplete="new-password"
                                                onChange={handleChange}
                                                value={values.password}
                                                icon={<LockIcon />}
                                            />
                                            {errors.password && touched.password ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <TextInput
                                                errored={errors.passwordConfirmation && touched.passwordConfirmation}
                                                type="password"
                                                inputPlaceholder={i18next.t('input-text.confirm-password')}
                                                name="passwordConfirmation"
                                                autoComplete="new-password"
                                                onChange={handleChange}
                                                value={values.passwordConfirmation}
                                                icon={<LockIcon />}
                                            />
                                            {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.passwordConfirmation}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <div className="flex justify-start text-grey2 items-center" onClick={() => setFieldValue('terms', true, false)}>
                                                <input
                                                    type="checkbox"
                                                    name="terms"
                                                    onChange={handleChange}
                                                    checked={values.terms}
                                                />
                                                <label
                                                    htmlFor="terms"
                                                    className="text-sm ml-2"
                                                >
                                                    {`${i18next.t('button-text.accept-terms-conditions.pt1')} `}<Link href="/terms-of-service" target="__blank">{i18next.t('button-text.accept-terms-conditions.pt2')}</Link>.
                                                </label>
                                            </div>
                                            {errors.terms && touched.terms ? (
                                                <div className="font-secondary text-[0.8rem] text-red">
                                                    {errors.terms}
                                                </div>
                                            ) : null}
                                        </div>
                                        <Button
                                            fullWidth
                                            loading={signUpMutation.isLoading}
                                            disabled={signUpMutation.isLoading}
                                            type="submit">
                                            {i18next.t('signup', { ns: 'header' })}
                                        </Button>

                                        <div />
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </AnimatePresence>
                </div>
                <div className="text-center">
                    <span className="text-[14px] font-[500] block">{`${i18next.t('general.already-signed-up')} `}<button onClick={() => changeToLogin()} className="text-blue3 font-[700]">{i18next.t('general.login')}</button></span>
                    <span className="block text-[14px] leading-[140%] pt-[24px]">{`${i18next.t('disclaimer.signup-form.pt1')} `}<Link href="/privacy-policy" target="__blank">{i18next.t('common:disclaimer.signup-form.pt2')}</Link>.</span>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm;
