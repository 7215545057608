import {
    MetaAvailableDto,
    SearchApi,
} from '@/api';
import { apiConfiguration } from '@/api/config';
import { SortOptions } from '@/types/API';

export const searchApi = new SearchApi(apiConfiguration);

export const searchMutationQueryFn = async ({ offset, limit, query }: { offset: number, limit: number, query: string }) => {
    return searchApi.searchControllerSearch(offset, limit, query);
};

export type MetaAvailableResponse = {
    category: MetaAvailableDto[];
    sub_category: MetaAvailableDto[];
    genres: MetaAvailableDto[];
    artists: MetaAvailableDto[];
    conditions: MetaAvailableDto[];
    collections: MetaAvailableDto[];
    offer_types: MetaAvailableDto[];
};

export type SearchResponse = Awaited<ReturnType<typeof searchApi.searchControllerSearch>> & { meta_available: MetaAvailableResponse };

export const searchQueryFn = async ({
    query,
    limit,
    offset,
    artists,
    genres,
    conditions,
    categories,
    subCategories,
    offerTypes,
    collections,
    sortBy
}: {
    query: string;
    limit: number;
    offset: number;
    artists: string[];
    offerTypes: string[];
    genres: string[];
    conditions: string[];
    collections: string[];
    categories: string[];
    subCategories: string[];
    sortBy?: SortOptions
}) => {
    return searchApi.searchControllerSearch(
        offset,
        limit,
        query,
        artists.length > 0 ? artists : undefined,
        genres.length > 0 ? genres : undefined,
        categories.length > 0 ? categories : undefined,
        subCategories.length > 0 ? subCategories : undefined,
        conditions.length > 0 ? conditions : undefined,
        collections.length > 0 ? collections : undefined,
        undefined,
        offerTypes.length > 0 ? offerTypes : undefined,
        undefined,
        sortBy
    );
};
