'use client';

import 'react-loading-skeleton/dist/skeleton.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useAuth, { AuthProvider } from '@/contexts/AuthContext';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorHandler } from '@/helpers/ErrorHandler';
import toast, { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { User } from '@/api';
import UserContext from '@/contexts/UserContext';
import CookieConsent from 'react-cookie-consent';
import { analytics } from '@/helpers/Analytics';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import AlertProvider from '@/providers/AlertProvider';
import { SingletonHooksContainer } from 'react-singleton-hook';
import SheetProvider from '@/providers/BottomSheetProvider';
import { UnAuthProvider } from '@/contexts/UnAuthContext';
import { I18nextProvider } from 'react-i18next';
import GlobalDataProvider, { useGlobalData } from '@/providers/GlobalDataProvider';
import Loader from '@/app/components/Loader';
import { CookiesHelper } from '@/helpers/CookiesHelper';
import Hotjar from '@hotjar/browser';
import { startBugsnag } from '@/helpers/Bugsnag';
import { Constants } from '@/helpers/Constants';
import * as uuid from 'uuid';
import { CMS } from '@/types/CMS';
import { AuctionListener } from '../util/AuctionListener';
import Seo from '../general/Seo';
import ErrorBoundary from '../general/ErrorBoundry';
import VerifyEmailPopup from '../general/VerifyEmailPopup';
import LoginModal from '../general/LoginModal';
import i18n from '../../../i18n';
import Button from '../general/Button';

const uuidv4 = uuid.v4;

const hasOwn = require('object.hasown');

if (!Object.hasOwn) {
    hasOwn.shim();
}

export type IMainProps = {
    pageTitle?: string;
    className?: string;
    pageDescription?: string;
    children: React.ReactNode;
    user: User | null;
    tryRefreshingToken: boolean;
    global: CMS.Global.Data;
};

const Main = (props: IMainProps) => {
    const { pageTitle, pageDescription, children, className, tryRefreshingToken, global } = props;
    const { checkToken, user } = useAuth();
    const didProcessLogout = useRef(false);
    const [showEmailVerificationRequired, setShowEmailVerificationRequest] = useState(false);
    const pathname = usePathname();
    const params = useSearchParams();
    const router = useRouter();
    const { setGlobal } = useGlobalData();

    useEffect(() => {
        startBugsnag();
    }, []);

    useEffect(() => {
        if (!CookiesHelper.hasCookie(Constants.Track.KEY_SESSION_COOKIE_NAME)) {
            CookiesHelper.setCookie(Constants.Track.KEY_SESSION_COOKIE_NAME, uuidv4());
        }
    }, []);

    const [openLoginSignup, setOpenLoginSignup] = useState<'login' | 'signup' | undefined>(undefined);

    const showSignUpOrLogin = useCallback((mode: 'login' | 'signup') => {
        if (user) {
            setOpenLoginSignup(undefined);
        } else {
            setOpenLoginSignup(mode);
        }
    }, [user]);

    useEffect(() => {
        if (global) {
            setGlobal(global);
        }
    }, [global, setGlobal]);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_HORJAR_SITE_ID) {
            const siteId = parseInt(process.env.NEXT_PUBLIC_HORJAR_SITE_ID, 10);
            const hotjarVersion = 6;

            Hotjar.init(siteId, hotjarVersion);
        }
    }, []);

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    mutations: {
                        retry: false
                    },
                    queries: {
                        retry: false,
                        refetchOnWindowFocus: false
                    },
                },
                queryCache: new QueryCache({
                    onError: async (e, query) => {
                        const exception = await ErrorHandler.transformError(e as Request | Error);
                        if (ErrorHandler.Auth.blocked(exception)) {
                            toast.error(i18n.t('error-notification.account-blocked'));
                        } else if (ErrorHandler.Auth.unauthorized(exception)) {
                            const success = await checkToken(true, false);
                            if (success) { query.fetch(); }
                        } else if (ErrorHandler.Auth.emailNotVerified(exception)) {
                            setShowEmailVerificationRequest(true);
                        }
                    },
                }),
                mutationCache: new MutationCache({
                    onError: async (e, vars, context, mutation) => {
                        const exception = await ErrorHandler.transformError(e as Request | Error);

                        if (ErrorHandler.Auth.blocked(exception)) {
                            toast.error(i18n.t('error-notification.account-blocked'));
                        } else if (ErrorHandler.Auth.unauthorized(exception)) {
                            const success = await checkToken(true, false);
                            if (success) { mutation.continue(); }
                        } else if (ErrorHandler.Auth.emailNotVerified(exception)) {
                            setShowEmailVerificationRequest(true);
                        }
                    },
                })
            })
    );

    useEffect(() => {
        if (user) {
            setOpenLoginSignup(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    useEffect(() => {
        if (!user && params.get('signin')) {
            showSignUpOrLogin('login');
        } else if (!user && params.get('signup')) {
            showSignUpOrLogin('signup');
        }
    }, [user, params, showSignUpOrLogin]);

    useEffect(() => {
        if (user) {
            analytics.identify(user.id, { email: user.email_address });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).dataLayer) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).dataLayer.push({
                    user_id: user.id
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    useEffect(() => {
        queryClient.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);

    useEffect(() => {
        analytics.page(pathname);
    }, [pathname]);

    const [refreshingToken, setRefreshingToken] = useState(tryRefreshingToken && didProcessLogout.current === false);

    useEffect(() => {
        if (tryRefreshingToken && didProcessLogout.current === false) {
            didProcessLogout.current = true;
            setRefreshingToken(CookiesHelper.hasRefreshToken());
            (async function check() {
                if (await checkToken()) {
                    if (params.get('redirect')) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        router.push(params.get('redirect')!);
                    }
                }
                setRefreshingToken(false);
            }());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tryRefreshingToken, checkToken]);

    useEffect(() => {
        if (pathname.includes('auth/confirm')) {
            return;
        }
        if (user?.is_email_confirmed) {
            setShowEmailVerificationRequest(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.is_email_confirmed]);

    if (refreshingToken) {
        return <Loader fullScreen />;
    }

    return (
        <UnAuthProvider showSignUpOrLogin={showSignUpOrLogin}>
            <QueryClientProvider client={queryClient}>
                <SheetProvider>
                    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID ?? ''}>
                        <div className={className && className}>
                            <Seo title={pageTitle} description={pageDescription} />
                            <SingletonHooksContainer />
                            <AuctionListener />

                            <ErrorBoundary>
                                <main className="font-secondary md:pt-[80px] pt-[68px] bg-white overflow-x-hidden">{children}</main>
                            </ErrorBoundary>
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        </div>
                        <Toaster position="top-center" toastOptions={{ duration: 6000, className: '!px-6 !py-3 font-secondary text-md' }} containerClassName="mb-6" />
                        <CookieConsent
                            location="bottom"
                            buttonText={i18n.t('button-text.accept')}
                            cookieName="th-consent"
                            cookieValue="true"
                            extraCookieOptions={{ domain: 'therealest.com' }}
                            ButtonComponent={Button}
                            containerClasses="!bg-white p-[15px] lg:px-[80px] xl:px-[120px] !text-black font-secondary shadow-[rgba(0,0,0,0.5)_1px_-1px_8px_0px] !items-center !w-[90%] md:!w-full rounded-full md:rounded-none !left-[5%] md:!left-[0] !bottom-4 md:!bottom-0 !flex-nowrap [&_button]:!min-w-[80px] [&_button]:md:!min-w-[140px]"
                            contentClasses="!m-0 !mx-3 md:!mx-0 !basis-0 text-sm md:text-[16px]"
                            expires={150}
                        >
                            {i18n.t('disclaimer.cookies-consent')}
                        </CookieConsent>
                        <LoginModal
                            type={openLoginSignup}
                            show={openLoginSignup !== undefined}
                            onHide={() => {
                                setOpenLoginSignup(undefined);
                            }} />
                        <VerifyEmailPopup
                            show={showEmailVerificationRequired}
                            onHide={() => {
                                setShowEmailVerificationRequest(false);
                            }} />
                    </GoogleOAuthProvider>
                </SheetProvider>
            </QueryClientProvider>
        </UnAuthProvider>
    );
};

const MainContainer = (props: IMainProps) => {
    const { user } = props;
    const [loggedInUser, setLoggedInUser] = useState<User | null>(user);

    const memoizedUser = useMemo(() => ({
        user: loggedInUser,
        setUser: (userObj: User | null) => { setLoggedInUser(userObj); }
    }), [loggedInUser, setLoggedInUser]);

    return (
        <I18nextProvider i18n={i18n} defaultNS="common">
            <GlobalDataProvider>
                <UserContext.Provider value={memoizedUser}>
                    <AlertProvider>
                        <AuthProvider>
                            <Main {...props} />
                        </AuthProvider>
                    </AlertProvider>
                </UserContext.Provider>
            </GlobalDataProvider>
        </I18nextProvider>
    );
};

export default MainContainer;
